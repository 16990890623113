import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ConnectButton } from '@/components/ConnectButton';
import { RouteName } from '@/constant/routeNames';


const ROUTES_SOLANA = [
  { url: RouteName.multipleCollection, name: 'MILK Market' },
  { url: RouteName.auctionsView, name: 'Auctions' },
  { url: RouteName.activityView, name: 'Activity' },
  { url: RouteName.sell, name: 'Sell' },
];

const getRoutes = () => {
  return ROUTES_SOLANA;
};

const TopNav: React.FC = () => {
  const [open] = useState(false);
  const anchorRef = useRef<HTMLLIElement>(null);

  const { pathname } = useLocation();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <HeaderBar>
      <Logo>
        <Link to={RouteName.multipleCollection}>
          <img alt="" src="/logo.png" />
        </Link>
      </Logo>
      <Menu>
      <li>
          <a href="https://bonk.udderchaos.io/">
          Bonk Market
          </a>
        </li>
        {getRoutes().map((item) => (
          <li key={item.url} className={pathname === item.url ? 'active' : ''}>
            <Link to={item.url}>{item.name}</Link>
          </li>
        ))}
      </Menu>
      <Wallet>
        <ConnectButton />
      </Wallet>
    </HeaderBar>
  );
};

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;

const Logo = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  img {
    height: 60px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: inline-flex;
  flex: 1 0 auto;
  margin-bottom: 0;

  > .active {
    border: 4px solid #7522f5;
    border-radius: 15px;
    background-color: #7522f5;
    padding: 3px;
    margin: 0 12px;
  }

  > .active-submenu {
    background-color: rgba(255, 255, 255, 0.08);
  }

  > li {
    margin: 0 12px;
    padding: 7px;

    a {
      color: var(--main-text-color);
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      outline: none;
      text-decoration: none;
      text-size-adjust: 00%;
      touch-action: manipulation;
      transition: color 0.3s;

      img {
        max-height: 26px;
      }
    }


    &:hover > a {
      color: rgb(131, 146, 161);
    }
  }
`;

export default TopNav;
