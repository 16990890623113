import { BlockchainType } from '@liqnft/candy-shop-sdk';
import { Blockchain } from '@liqnft/candy-shop-types';
import { networkIcon, walletIcon } from '@/assets/img/wallet';

export enum WalletName {
  Phantom = 'Phantom',
  Solflare = 'Solflare',
}

enum Network {
  SolMainnetBeta = 'Mainnet Beta',
}
export interface WalletAdapter {
  name: WalletName;
  icon: string;
  networks: {
    name: Network;
    network: Blockchain;
    icon: string;
  }[];
}

export const Wallets: WalletAdapter[] = [
  {
    name: WalletName.Phantom,
    icon: walletIcon.phantom,
    networks: [
      {
        name: Network.SolMainnetBeta,
        network: Blockchain.SolMainnetBeta,
        icon: networkIcon.solana.mainnet,
      },
    ],
  },
  {
    name: WalletName.Solflare,
    icon: walletIcon.solflare,
    networks: [
      {
        name: Network.SolMainnetBeta,
        network: Blockchain.SolMainnetBeta,
        icon: networkIcon.solana.mainnet,
      },
    ],
  },
];

export const WalletsSupport: Record<BlockchainType, Array<{ name: WalletName; icon: string }>> = {
  [BlockchainType.SOL]: [
    { name: WalletName.Phantom, icon: walletIcon.phantom },
    { name: WalletName.Solflare, icon: walletIcon.solflare },
  ],
  [BlockchainType.EVM]: [],
};

export const Networks = Wallets.map((wallet) => wallet.networks).flat(Infinity) as Array<{
  name: Network;
  network: Blockchain;
  icon: string;
}>;

export const InferWalletName: { [key in Blockchain]?: WalletName } = {
  [Blockchain.SolMainnetBeta]: WalletName.Phantom,
};
